import React from 'react'
import Link from 'gatsby-link'
import { MDBMask, MDBRow, MDBCol, MDBView, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRss, faChevronRight, faMessageMiddle } from '@fortawesome/pro-regular-svg-icons'

const Hero = (props) => {
  const bgImage = `linear-gradient(to right, rgba(0,0,0,0.6) 30%, rgba(0,0,0,0.2) 100%), url(${props.image})`

  return (
    <div
      className={props.class}
      style={{backgroundImage: bgImage, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}
    >
      <MDBView>
        <MDBMask className={ props.gradientClass != null ? props.gradientClass : 'mask d-flex justify-content-center gradient' } >
          <MDBContainer className="h-100">
            <MDBRow className="align-items-center h-100">
              {
              props.type === 'root' ||
              props.type === 'methodology' ||
              props.type === 'contact' ||
              props.type === 'search' ||
              props.type === 'sitemap' ||
              props.type === '404' ? (
                <>
                  <MDBCol lg="6" className="text-lg-left text-center">
                    <h1
                      className="font-alt font-w-700 text-white title-large title-extra-large-1"
                      dangerouslySetInnerHTML={{ __html: props.title }}
                    />

                    {props.subtitle && 
                      <h2
                        className="font-alt font-w-400 title-xs-medium title-small text-white letter-spacing-1 mt-5 mb-xl-5"
                        dangerouslySetInnerHTML={{ __html: props.subtitle, }}
                      />
                    }

                    {props.ctaslug && (
                      <>
                      {props.ctaslug.substring(0,4) === 'http' ?
                        <a href={props.ctaslug} target="_blank" className="nav-link btn btn-mdb-color mr-0 mt-5 mt-lg-2">
                          <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                          {props.ctatext ? props.ctatext : 'Schedule a consultation'}
                        </a>
                      :
                        <Link to={props.ctaslug} className="btn btn-mdb-color mr-0 mt-5 mt-lg-2" >
                          <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                          {props.ctatext != '' && props.ctatext != null ? props.ctatext : 'Read'}
                        </Link>
                      }
                      </>
                    )}
                  </MDBCol>
                </>
              ) : props.type === 'blog' ? (
                <MDBCol lg="12" className="mx-auto text-lg-left mt-3 mt-lg-0">
                  <h1
                    className="font-alt font-w-700 text-white title-large title-extra-large-1 mb-3 mx-3"
                    dangerouslySetInnerHTML={{ __html: props.title }}
                  />
                  {props.subtitle != '' && props.subtitle != null && (
                      <h2
                        className="text-white font-w-400 font-alt mb-4 mb-md-4 title-xs-medium title-small mx-3 letter-spacing-1"
                        dangerouslySetInnerHTML={{ __html: props.subtitle, }}
                      />
                  )}

                  {props.ctaslug != '' && props.ctaslug != null && (
                    <Link to={props.ctaslug} className="btn btn-mdb-color mr-0 mt-xl-3" >
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                      {props.ctatext != '' && props.ctatext != null ? props.ctatext : 'Read'}
                    </Link>
                  )}
                </MDBCol> 
              ) : props.type === 'news' ? (
                <MDBCol lg="12" className="mx-auto text-lg-left mt-3 mt-lg-0">
                  <h1
                    className="font-alt font-w-700 text-white title-large title-extra-large-1 mb-3"
                    dangerouslySetInnerHTML={{ __html: props.title }}
                  />
                  {props.subtitle != '' && props.subtitle != null && (
                    <h2
                      className="text-white font-w-400 font-alt mb-4 mb-md-4 title-xs-medium title-small letter-spacing-1"
                      dangerouslySetInnerHTML={{ __html: props.subtitle, }}
                    />
                  )}

                  {props.ctaslug != '' && props.ctaslug != null && (
                    <a href={props.ctaslug} target="_blank" className="btn btn-mdb-color mr-0 mt-xl-3" >
                      <FontAwesomeIcon icon={faRss} className="mr-2 va-m" />{' '}
                      {props.ctatext != '' && props.ctatext != null ? props.ctatext : 'RSS Feed'}
                    </a>
                  )}
                </MDBCol>
              ) : (
                <div className="text-white text-center col-md-12 mt-xl-2">
                  <h1
                    className="font-alt font-w-700 text-white title-large title-extra-large-1 mt-sm-5 mb-xl-4"
                    dangerouslySetInnerHTML={{ __html: props.title }}
                  />
                  {props.subtitle != '' && props.subtitle != null && (
                      <h2
                        className="text-white font-alt font-w-400 mb-4 title-xs-medium title-small letter-spacing-1"
                        dangerouslySetInnerHTML={{ __html: props.subtitle, }}
                      />
                    )}

                    {props.ctaslug && (
                    <>
                    {props.ctaslug.substring(0,4) === 'http' ?
                      <a href={props.ctaslug} target="_blank" className="nav-link btn btn-mdb-color mr-0 mt-5 mt-lg-2">
                        <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                        {props.ctatext ? props.ctatext : 'Schedule a consultation'}
                      </a>
                    :
                      <Link to={props.ctaslug} className="btn btn-mdb-color mr-0 mt-5 mt-lg-2" >
                        <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                        {props.ctatext != '' && props.ctatext != null ? props.ctatext : 'Read'}
                      </Link>
                    }
                    </>
                  )}
                </div>
              )}
            </MDBRow>
          </MDBContainer>
        </MDBMask>
      </MDBView>
    </div>
  )
}

export default Hero